.horizontal-scroll {
    overflow-x: scroll;
    white-space: nowrap;
    max-width: 100%;
    /*min-width: 300px;  !* set a minimum width to prevent it from becoming too narrow *!*/
}

/* Reset flex behavior for direct children to stack them horizontally */
.horizontal-scroll > * {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
}